import { defineStore } from 'pinia';

export const useMyAuthStore = defineStore({
  id: 'myAuthStore',
  state: () => ({}),
  actions: {
    async google() {
      try {
        window.location.href = `${useRuntimeConfig().public.apiUrl}/auth/google`;
      } catch (err) {
        throw err;
      }
    },
    async github() {
      try {
        window.location.href = `${useRuntimeConfig().public.apiUrl}/auth/github`;
      } catch (err) {
        throw err;
      }
    },
  },
});
